import React from 'react';
import './App.css';
import {Link} from "react-router-dom";

function MainPage() {

    let a = 1;
    a = 2;

    return (
        <div className="App">



            <header className="App-header">

                <img src={"/footer-bg.jpg"} style={{height: 'auto', width: '100%'}}/>
                <br/>
                Xobox Game

                <p style={{color: 'gray', fontSize: 16}}>
                    XoboX is an addictive noughts and crosses game enriched with an extra dimension
                    that will draw you into long hours of play and fun for everyone in any age.
                </p>

                <br/>
                <p style={{color: 'gray', fontSize: 16}}>
                    Get the game from:
                    &nbsp; <a href={"https://store.steampowered.com/app/946240/Xobox__circle_and_cross/"}>Steam</a>
                    &nbsp; or VR:
                    &nbsp; <a href={"https://www.oculus.com/experiences/rift/1390969547685803/"}>Oculus</a>
                    &nbsp; and
                    &nbsp; <a href={"https://www.viveport.com/1c854788-212c-4222-9676-fc085df056fa"}>VIVEPORT</a>
                </p>

            </header>

            <footer>
                2014-2021 R&DLab @ All Rights Reserved @ <Link to={"/privacy"}>Privacy Policy</Link>
            </footer>
        </div>
    );
}

export default MainPage;