import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MainPage from "./MainPage";
import PrivacyPolicyPage from "./PrivacyPolicyPage";

function App() {

  let a = 1;
  a = 2;

  return (
    <BrowserRouter>
        <Routes>
            <Route path="/privacy" element={<PrivacyPolicyPage />} />
            <Route path="/en/privacy.html" element={<PrivacyPolicyPage />} />
            <Route path="*" element={<MainPage />} />
        </Routes>
    </BrowserRouter>
  );
}

export default App;
