import React from 'react';
import './App.css';
import {Link} from "react-router-dom";

function PrivacyPolicyPage() {

    return (
        <div className="App">
            <header className="App-header">
                <img src={"/footer-bg.jpg"} style={{height: 'auto', width: '100%'}}/>
                <br/>
                Privacy Policy for Xobox Game

                <p style={{color: 'gray', fontSize: 16}}>
                    <p><b>1. </b> </p>
                    <p>
                        Your privacy is very important to us. Accordingly, we have developed this Policy in order for you to understand how we collect,
                        use, communicate and disclose and make use of personal information. The following outlines our privacy policy.
                    </p>
                    <p><b>2. </b> </p>
                    <p>
                        Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.
                    </p>
                    <p><b>3. </b> </p>
                    <p>
                        We will collect and use of personal information solely with the objective of fulfilling those purposes specified by us
                        and for other compatible purposes, unless we obtain the consent of the individual concerned or as required by law.
                    </p>
                    <p><b>4. </b> </p>
                    <p>
                        We will only retain personal information as long as necessary for the fulfillment of those purposes.
                    </p>
                    <p><b>5. </b> </p>
                    <p>
                        We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.
                    </p>
                    <p><b>6. </b> </p>
                    <p>
                        Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.
                    </p>
                    <p><b>7. </b> </p>
                    <p>
                        We will protect personal information by reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification.
                    </p>
                    <p><b>8. </b> </p>
                    <p>
                        We will make readily available to customers information about our policies and practices relating to the management of personal information.
                    </p>
                    <p><b>10. </b> </p>
                    <p>
                        We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained.
                    </p>
                    <p><b>11. </b> </p>
                    <p>
                        Google Analitycs (<a href="https://marketingplatform.google.com">https://marketingplatform.google.com</a>) gather statistic information about use of our websites. We don't store this data.
                        We use it to improve your UX experience. You can read their privacy: <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a>
                        &nbsp; and terms: <a href="https://policies.google.com/terms?hl=en">https://policies.google.com/terms?hl=en</a>.
                    </p>
                    <p><b>12. </b> </p>
                    <p>
                        Observer Analitycs (<a href="https://observeranalytics.com/">https://observeranalytics.com/</a>) gather information and statistics about using VR. We don't store this data. We use their data to improve your VR experience.
                        Observer Analitycs privacy you can read at this link: <a href="https://storage.googleapis.com/observr-static/Legal/PrivacyPolicy.pdf">https://storage.googleapis.com/observr-static/Legal/PrivacyPolicy.pdf</a>
                        &nbsp; and their terms: <a href="https://storage.googleapis.com/observr-static/Legal/TermsOfService.pdf">https://storage.googleapis.com/observr-static/Legal/TermsOfService.pdf</a>.
                    </p>
                    <p><b>13. </b> </p>
                    <p>
                        Backtrace (<a href="https://backtrace.io/">https://backtrace.io/</a>) is our crash reporting system. When the game crash you are asked to send crash data for their servers.
                        We don't store this data. We use this data to improve stability and fix the game in the cases of crashes.
                        Backtrace privacy: <a href="https://backtrace.io/privacy-policy/">https://backtrace.io/privacy-policy/</a>.
                    </p>
                </p>

            </header>

            <footer>
                2014-2021 R&DLab @ All Rights Reserved @ <Link to={"/"}>Home Page</Link>
            </footer>
        </div>
    );
}

export default PrivacyPolicyPage;